import React, { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { ServerConfig } from 'config'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/actions/auth.js'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import List from '../../components/EntryListAndEdit/List'

import LocationCard from '../../components/Map/LocationCard';
import LocationsMap from '../../components/Map/LocationsMap';
// for geo-lookups of addresses
import { geocode, RequestType } from "react-geocode";

// for csv-file-handling
import * as Papa from 'papaparse';

import PaginatedList from '../../components/PaginatedList/PaginatedList'


import { getAllowedRoutes } from 'utils';
import _, { get } from 'lodash'

const evt_ledger = new Event('ledger_updated');

const useStyles = makeStyles((theme) => ({}))


const Customers = () => {
  const auth = useSelector(state => state.auth)
  const [defaultZoom, setDefaultZoom] = useState(4);
  const [defaultCenter, setDefaultCenter] = useState({lat:40,lng:-98});

  const [zipcodeMap, setZipcodeMap] = useState({});
  const [myLocations, setMyLocations] = useState([]);
  const [mapDataReady, setMapdataReady] = useState(false);

    useEffect(() => {
      let my_map = {};

      fetch('/2023_Gaz_zcta_national.txt')
        .then(response => response.text() )
        .then(responseText => {
            var data = Papa.parse(responseText, {header: true});

            _.map(data.data, datapoint => {
                my_map[datapoint['GEOID']] = [parseFloat(datapoint['INTPTLAT']), parseFloat(datapoint['INTPTLONG'])];
            });

            setZipcodeMap(my_map);
            console.log("zipcodeMap:", my_map);
        });
        }, []) // fetch zipcode-maps


  useEffect(() => {
    fetchCustomerZipcodes(auth.access_token);
  }, [auth.access_token, zipcodeMap])

  useEffect(() => {
    setMapdataReady(true)
  }, [myLocations])

  if(!auth.access_token){
    return <Redirect to="/login" />
  }

  async function fetchCustomerZipcodes(token) {
    const config = { headers: { 'Content-Type': 'application/json' } }

    if (auth.access_token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    const results = await axios.get(`${ServerConfig.SERVER_URL}/admin/customer/zipcodes`, config)

    let tmp_locations = [];
    _.map(results.data, zipcode => {
        //tmp_locations.push(zipcodeMap[customer.zipcode]);
        let thiszip = parseInt(zipcode);
        if(zipcodeMap[thiszip] !== undefined){
            tmp_locations.push({'lat': zipcodeMap[thiszip][0], 'lng': zipcodeMap[thiszip][1], 'info': 'No info', 'data': 'No data'})
        }else {
            console.log("Could not find customer-zipcode ",thiszip, " in zipcodemap");
        }
     }
    );
    console.log("tmp_locations:",tmp_locations);
    setMyLocations(tmp_locations);
  }

  /*const locations = [
    {'lat': 34, 'lng': -97, 'info': 'No info', 'data': 'No data'},
    {'lat': 36, 'lng': -93, 'info': 'No info', 'data': 'No data'}
]*/

  const editable_fields = [
    ["first_name", "First name"],
    ["last_name", "Last name"],
    ["business_name", "Business name"],
    ["email", "Email"],
    ["paypal_email", "Paypal Email"],
    ["phone_number", "Phone number"],
    ["street", "Street"],
    ["housenumber", "Housenumber"],
    ["zipcode", "Zipcode"],
    ["city", "City"],
    ["state", "State"],
    ["two_factor_auth_on_login", "2FA on Login", "boolean"],
    ["two_factor_auth_on_withdrawal", "2FA on Withdrawal", "boolean"],
  ];

  const non_editable_fields = [
    ["onbe_linked_card_package_id", "Onbe Cardpackage ID"],
    ["onbe_account_number", "Onbe Account #"],
    ["onbe_program_id", "Onbe Program ID"],
  ];

  return (
    <>
        {(myLocations.length > 0) &&
            <LocationsMap
              height={'400px'}
              zoom={defaultZoom}
              center={[Number(defaultCenter.lat), Number(defaultCenter.lng)]}
              locations={myLocations}
            />
        }

        <br />

        <PaginatedList
            list_get_url={`${ServerConfig.SERVER_URL}/admin/customer/`}
            detail_get_url={`${ServerConfig.SERVER_URL}/customer/`}
            detail_put_url={`${ServerConfig.SERVER_URL}/customer/`}

            column_names={["first_name", "last_name", "email"]}
            header_names={["First Name", "Last Name", "EMail"]}

            optional_column_names={["zipcode", "city", "housenumber", "street", "phone_number"]}
            optional_header_names={["Zip", "City", "Hnr", "Street", "Phone"]}

            editable_fields={editable_fields}
            non_editable_fields={non_editable_fields}

            ledger_get_url='/ledger/customer/'
            ledger_allow_entries={true}
        />
    </>
  )
}

export default Customers
