import React, { useEffect } from 'react'
import { ServerConfig } from 'config'
import axios from 'axios'
import { useSelector } from 'react-redux'
//import Progress from 'react-circle-progress-bar'
import { makeStyles } from '@material-ui/core/styles'

// @material-ui/core components
import { Typography, Modal, Button, Box, Grid } from '@material-ui/core'
import _, { get } from 'lodash'

import EditIcon from '@material-ui/icons/Edit';

import LedgerComponent from './LedgerComponent'

const evt = new Event('customertable_is_dirty');

const useStyles = makeStyles((theme) => ({
  detailsBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    backgroundColor: theme.palette.background.default,
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4,
  },
}))


const DetailsEditComponent = (props) => {
  const [details, setDetails] = React.useState({})
  const [timeZone, setTimeZone] = React.useState("America/New_York");
  const auth = useSelector(state => state.auth)
  const rowData = props.rowdata;

  const detail_get_url = props.detail_get_url;
  const detail_put_url = props.detail_put_url;
  const detail_delete_url = props.detail_delete_url;
  const ledger_get_url = props.ledger_get_url;
  const ledger_allow_entries = props.ledger_allow_entries;
  const show_machine_stats = props.show_machine_stats;

  var editable_fields = props.editable_fields ;
  if(editable_fields === undefined){
    editable_fields = [];
  }

  var non_editable_fields = props.non_editable_fields;
  if(non_editable_fields === undefined){
    non_editable_fields = [];
  }

  useEffect(() => {
    const fetchDetails = async () => {
      const config = { headers: { 'Content-Type': 'application/json' } }

      if (auth.access_token) {
        config.headers.Authorization = `Bearer ${auth.access_token}`
      }

      const results = await axios.get(detail_get_url + encodeURIComponent(rowData.id), config)

      setDetails(results.data)
    }

    fetchDetails();
  }, [])


  // submit data and update object
  const handleSubmit = async (event) => {
    const config = { headers: { 'Content-Type': 'application/json' } }

    if (auth.access_token) {
      config.headers.Authorization = `Bearer ${auth.access_token}`
    }

    event.preventDefault();
    const data = new FormData(event.target);
    const value = Object.fromEntries(data.entries());
    const res = await axios.put(detail_put_url + encodeURIComponent(rowData.id), value, config);

    document.getElementById('details_status').innerHTML = "Updated";
    window.dispatchEvent(evt);
  }

  const handle_delete = async (ledger_id) => {
    const config = { headers: { 'Content-Type': 'application/json' } }

    if (auth.access_token) {
      config.headers.Authorization = `Bearer ${auth.access_token}`
    }

    console.log("Deleting " + ledger_id);
    const res = await axios.get(detail_delete_url + rowData.id, config);
    document.getElementById('delete_status').innerHTML = "Deleted";
    window.dispatchEvent(evt);
  }

    const processChangelist = (fieldname) => {
        let changelist = details[fieldname];
        if(changelist !== undefined){
            changelist.map(function(change, bla){
                return {change}
            })
        }
    }

  return (
    <>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Details {rowData.id}
      </Typography>

      <hr />

      {
        non_editable_fields.map(function(fieldname, bla){
            switch (fieldname[2]) {
              case "date":
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                  <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                  <input disabled type="date" className="form-control" name={fieldname[0]} id={fieldname[0]} defaultValue={details[fieldname[0]]} />
                </div>
                break
              case "boolean":
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px '}}>
                    <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                    <input disabled type="checkbox" name={fieldname[0]} id={fieldname[0]} defaultValue={details[fieldname[0]]} />
                </div>
              case "currency":
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                  <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                  <input disabled type="text" className="form-control" name={fieldname[0]} id={fieldname[0]} value={"$"+(details[fieldname[0]]/1e6).toFixed(2)}  />
                </div>
                break
              case "timestamp":
				return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                  <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                  <input disabled type="text" className="form-control" name={fieldname[0]} id={fieldname[0]} value={(new Date(details[fieldname[0]])).toLocaleDateString("en-US", {timeZone: timeZone}) + " " + (new Date(details[fieldname[0]])).toLocaleTimeString("en-US", {timeZone: timeZone})}  />
                </div>
                break
              case "weight":
              case "count":
                if(details[fieldname[0]] !== undefined && details[fieldname[0]] != 0){
                    return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                      <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                      <input disabled type="text" className="form-control" name={fieldname[0]} id={fieldname[0]} value={details[fieldname[0]]}  />
                    </div>
                }
                break
              case "dont_show_if_empty":
                if(details[fieldname[0]] !== undefined){
                    return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                      <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                      <input disabled type="text" className="form-control" name={fieldname[0]} id={fieldname[0]} value={details[fieldname[0]]}  />
                    </div>
                }
                break
              case "changelist":
                if(details[fieldname[0]] === undefined){
                    return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                      <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                      <input disabled type="text" className="form-control" name={fieldname[0]} id={fieldname[0]} value="No changes"  />
                    </div>
                }else if(details[fieldname[0]].length === 0 ){
                    return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                      <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                      <input disabled type="text" className="form-control" name={fieldname[0]} id={fieldname[0]} value="No changes"  />
                    </div>
                }else{
                    return <div className="form-group" style={{'float': 'left', 'margin': '10px', 'width': '100%'}}>Changelist:<br/>
                        <table style={{}}>
                        <tr style={{}}><th>Type</th><th>Subject</th><th>Old val</th><th>&nbsp;</th><th>New val</th></tr>
                        {details[fieldname[0]].map(function(change, bla){
                        if(change.new_value_count !== null){
                            if(change.old_value_count === null){
                                return <tr><td>[C]</td><td>{change.subject}</td><td></td><td>-></td><td>{change.new_value_count}</td></tr>
                            }else{
                                return <tr><td>[C]</td><td>{change.subject}</td><td>{change.old_value_count}</td><td>-></td><td>{change.new_value_count}</td></tr>
                            }

                        }else{
                            if(change.old_value_weight_g === null){
                                return <tr><td>[W]</td><td>{change.subject}</td><td></td><td>-></td><td>{change.new_value_weight_g}g</td></tr>
                            }else{
                                return <tr><td>[W]</td><td>{change.subject}</td><td>{change.old_value_weight_g}g</td><td>-></td><td>{change.new_value_weight_g}g</td></tr>
                            }

                        }
                        })}
                        </table></div>
                }
                break
              case "b64image":
                if(details[fieldname[0]] !== undefined){
                  return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                    <img class="form-control" src={`data:image/jpeg;base64,${details[fieldname[0]]}`} style={{'width':'260px'}}/>
                    </div>
                }
                break
              default:
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                  <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                  <input disabled type="text" className="form-control" name={fieldname[0]} id={fieldname[0]} defaultValue={details[fieldname[0]]}  />
                </div>
                break
            }
        })
      }

      <form onSubmit={handleSubmit}>
        {
          editable_fields.map(function (fieldname, bla) {
            switch (fieldname[2]) {
              case "date":
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                  <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                  <input type="date" className="form-control" name={fieldname[0]} id={fieldname[0]} defaultValue={details[fieldname[0]]} onChange={() => { document.getElementById('details_status').innerHTML = "Unsaved changes" }} />
                </div>
                break
              case "boolean":
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px '}}>
                    <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': "150px" }}>{fieldname[1]}
                        <input type="hidden" name={fieldname[0]} value={false} />
                        <input type="checkbox" style={{'marginLeft': '15px'}} name={fieldname[0]} id={fieldname[0]} value={true} defaultChecked={details[fieldname[0]]} onChange={() => { document.getElementById('details_status').innerHTML = "Unsaved changes" }} />
                    </label>
                </div>
                break
              default:
                return <div className="form-group" style={{ 'float': 'left', 'margin': '10px' }}>
                  <label htmlFor={fieldname[0]} style={{ 'display': 'block', 'width': 150 + "px" }}>{fieldname[1]}</label>
                  <input type="text" className="form-control" name={fieldname[0]} id={fieldname[0]} defaultValue={details[fieldname[0]]} onChange={() => { document.getElementById('details_status').innerHTML = "Unsaved changes" }} />
                </div>
                break
            }

          })
        }
        {editable_fields.length > 0 &&
            <>
            <br style={{ 'clear': 'both' }} />
            <Button type='submit' style={{ 'margin': '10px', 'display': 'block', 'float': 'left' }}>Save</Button>
            <Typography id="details_status" variant="h6" component="h2" style={{ 'lineHeight': '36px', 'padding': '10px' }}></Typography>
            </>
        }
      </form>

      {detail_delete_url !== undefined &&
            <>
            <br style={{ 'clear': 'both' }} />
            <Button onClick={() => handle_delete(rowData.id)} style={{ 'backgroundColor': "#ff0000", 'margin': '10px', 'display': 'block', 'float': 'left' }}>Delete</Button>
            <Typography id="delete_status" variant="h6" component="h2" style={{ 'lineHeight': '36px', 'padding': '10px' }}></Typography>
            </>
        }

      {(ledger_get_url !== undefined) &&
        <LedgerComponent rowdata={rowData} ledger_get_url={ledger_get_url} ledger_allow_entries={ledger_allow_entries} />
      }

      <hr style={{ 'clear': 'both' }} />

      {(show_machine_stats !== undefined) &&
        <>
          <Grid container>

            <Grid item sm={6} md={2}>
              <Box textAlign='center'>
                <Typography variant="body">MLRS Version</Typography><br style={{ 'clear': 'both' }} />
                <span style={{ 'font-size': '24px', 'font-weight': 'bold' }}>{details['mlrs_version']}</span>
              </Box>
            </Grid>

            <Grid item sm={6} md={4}>
              <Box textAlign='center'>
                <Typography variant="body">Modelweights</Typography><br style={{ 'clear': 'both' }} />
                <span style={{ 'font-size': '24px', 'font-weight': 'bold' }}>{details['modelweights_path'] !== undefined && details['modelweights_path'].split("/").slice(-2)[0]}</span>
              </Box>
            </Grid>

            <Grid item sm={6} md={2}>
              <Box textAlign='center'>
                <Typography variant="body">Currently online:</Typography><br style={{ 'clear': 'both' }} />
                <span style={{ 'font-size': '40px', 'font-weight': 'bold' }}>{details['online']}</span>
              </Box>
            </Grid>

            <Grid item sm={6} md={2}>
              <Box textAlign='center'>
                <Typography variant="body">#Containers counted:</Typography><br style={{ 'clear': 'both' }} />
                <span style={{ 'font-size': '40px', 'font-weight': 'bold' }}>{details['total_containers']}</span>
              </Box>
            </Grid>

            <Grid item sm={6} md={2}>
              <Box textAlign='center'>
                <Typography variant="body">Runtime (s):</Typography> <br style={{ 'clear': 'both' }} />
                <span style={{ 'font-size': '40px', 'font-weight': 'bold' }}>{details['total_runtime_s']}</span>
              </Box>
            </Grid>

          </Grid>

          <hr />

          <Grid container>
            <Grid item sm={6} md={3}>
              <Box textAlign='center'>
                <Typography>CPU: {details['cpu_used_perc']}%</Typography>
                {/* <Progress progress={details['cpu_used_perc']} style={{ 'width': '50%', 'margin-left': 'auto', 'margin-right': 'auto' }} /> */}
              </Box>
            </Grid>

            <Grid item sm={6} md={3}>
              <Box textAlign='center'>
                <Typography>RAM: {details['ram_used_perc']}%</Typography>
                {/*<Progress progress={details['ram_used_perc']} style={{ 'width': '50%', 'margin-left': 'auto', 'margin-right': 'auto' }} />*/}
              </Box>
            </Grid>

            <Grid item sm={6} md={3}>
              <Box textAlign='center'>
                <Typography>HDD-1: {details['disk1_used_perc']}%</Typography>
                {/*<Progress progress={details['disk1_used_perc']} style={{ 'width': '50%', 'margin-left': 'auto', 'margin-right': 'auto' }} />*/}
              </Box>
            </Grid>

            <Grid item sm={6} md={3}>
              <Box textAlign='center'>
                <Typography>HDD-2: {details['disk2_used_perc']}%</Typography>
                {/*<Progress progress={details['disk2_used_perc']} style={{ 'width': '50%', 'margin-left': 'auto', 'margin-right': 'auto' }} />*/}
              </Box>
            </Grid>
          </Grid>
        </>

      }
    </>
  );
}

// handles button and modal overlay
const EditComponent = (props) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles()
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const auth = useSelector(state => state.auth)

  const rowData = props.rowData;
  const editable_fields = props.editable_fields;
  const non_editable_fields = props.non_editable_fields;
  const detail_get_url = props.detail_get_url;
  const detail_put_url = props.detail_put_url;
  const detail_delete_url = props.detail_delete_url;
  const ledger_get_url = props.ledger_get_url;
  const ledger_allow_entries = props.ledger_allow_entries;
  const show_machine_stats = props.show_machine_stats;

  useEffect(() => { }, [open]);

  return (
    <td>
      <Button onClick={handleOpen} style={{'padding': '8px'}}><EditIcon /></Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.detailsBox} style={{ 'maxHeight': '100vh', 'overflowY': 'auto' }}>

          <DetailsEditComponent
            rowdata={rowData}
            editable_fields={editable_fields}
            non_editable_fields={non_editable_fields}
            detail_get_url={detail_get_url}
            detail_put_url={detail_put_url}
            detail_delete_url={detail_delete_url}
            ledger_get_url={ledger_get_url}
            ledger_allow_entries={ledger_allow_entries}
            show_machine_stats={show_machine_stats}
          />
        </Box>
      </Modal>
    </td>
  );
};

export default EditComponent;
